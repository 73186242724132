// ** React Imports
import { createElement } from "react";

// ** Third Party Components
import styled from "styled-components";

// #################################################################

export const StyledWrapperDefault = styled(({ ...props }) =>
	// Jest błąd w nazwie parametru - powinien się nazywać "noMinHeight", a nie "noMinWidth"
	// Nie zmieniam, bo jest ten parametr jest już używany w wielu miejscach
	createElement("div", props)
)`
	position: relative;
`;

// #################################################################
