import React from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";
import BtnContent from "../Btn/components/BtnContent";

// ** Custom Styles
import { resolveProps, marginable } from "styles/styledComponentsMixins";

// #####################################################

const StyledButtonPrimary = styled(({ ...props }) => (
	<Button {...resolveProps(props, marginable)} />
))(({ block, textLeft, noBoxShadow, fakeDisabled }) => {
	return css`
		position: relative;
		display: inline-block;
		white-space: normal;
		text-wrap: nowrap;
		text-align: ${textLeft ? "left" : "center"};
		border-radius: var(--dz-sys-button-border-radius);
		font-size: 1rem;
		line-height: 1.5rem;
		padding: 0 1rem;
		height: 35px;
		background-color: var(--dz-sys-button-primary-background-color);
		color: var(--dz-sys-button-primary-text-color);
		box-shadow: ${noBoxShadow ? "none" : "var(--dz-sys-button-shadow)"};
		border: 1px solid var(--dz-sys-button-primary-background-color-hover);

		${block &&
		css`
			width: 100%;
		`}

		${marginable.css}

        &:hover,
        &:focus {
			background-color: var(
				--dz-sys-button-primary-background-color-hover
			);
		}

		&:active {
			background-color: var(
				--dz-sys-button-primary-background-color-active
			);
		}

		&:disabled {
			&:hover,
			&:focus,
			&:active {
				background-color: var(--dz-sys-button-primary-background-color);
			}
		}

		${fakeDisabled &&
		css`
			opacity: var(--dz-sys-button-disabled-opacity);

			&:hover,
			&:focus,
			&:active {
				background-color: var(--dz-sys-button-primary-background-color);
			}
		`}
	`;
});

// #####################################################

export const ButtonPrimary = ({
	noPointerEffect,
	label,
	children,
	fakeDisabled,
	...rest
}) => {
	const textContent = children || label;

	return (
		<StyledButtonPrimary fakeDisabled={fakeDisabled} {...rest}>
			<BtnContent
				noPointerEffect={noPointerEffect}
				textContent={textContent}
			/>
		</StyledButtonPrimary>
	);
};

// #####################################################
